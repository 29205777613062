import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';
import { lumosBlack } from '@lumoslabs/react_lux';
import Helmet from 'react-helmet';
import Layout from 'components/layout';
import SubNav from 'components/legal/subNav';
import Metatags from 'components/Metatags';
import i18n from 'utils/i18n';
import * as contentHtml from './content';

const styles = StyleSheet.create({
  lastModified: {
    color: '#555555',
    paddingTop: 20
  },
  bodyContent: {
    color: lumosBlack,
    wordWrap: 'break-word',
    paddingBottom: 60
  }
});

const legalPagesData = {
  terms_of_service: {
    key: 'termsOfService',
    lastModified: '2019-11-05'
  },
  privacy_policy: {
    key: 'privacyPolicy',
    lastModified: '2024-06-08'
  },
  payment_policy: {
    key: 'paymentPolicy',
    lastModified: '2018-11-15'
  },
  responsible_disclosure_policy: {
    key: 'responsibleDisclosurePolicy',
    lastModified: '2019-08-08'
  }
};

function LegalIndex({ location, pageContext: { locale, pagePath, slug } }) {
  const renderedAsWebview = location.href && location.href.includes('layout=webview');
  const legalPageData = legalPagesData[slug];
  const title = i18n.t(`legal.${legalPageData.key}.title`);
  const metaDescription = i18n.t(`legal.${legalPageData.key}.metaDescription`);
  const copyFile = contentHtml[`${slug}_${locale}`];
  const lastModified = `${legalPageData.lastModified}`;

  if (!copyFile) {
    throw new Error(`Content for ${slug}_${locale} not found`);
  }

  return (
    <Layout location={location} locale={locale}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Metatags
        title={`Lumosity - ${title}`}
        description={metaDescription}
        thumbnail="https://asset.lumosity.com/resources/static/press/logos/lumosity_logo.png"
        imageWidth="573"
        imageHeight="148"
        canonical={pagePath}
        locale={locale}
      />
      <SubNav title={title} locale={locale} renderedAsWebview={renderedAsWebview} />
      <Container>
        <Row className={css(styles.lastModified)}>
          <Col xs="12">{i18n.t('legal.lastModified', { lastModified })}</Col>
        </Row>
        <Row>
          <Col
            xs="12"
            className={css(styles.bodyContent)}
            dangerouslySetInnerHTML={{
              __html: copyFile
            }}
          />
        </Row>
      </Container>
    </Layout>
  );
}

LegalIndex.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.string.isRequired
  }).isRequired,
  pageContext: PropTypes.shape({
    pagePath: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired
  }).isRequired
};

export default LegalIndex;
