import React from 'react';
import PropTypes from 'prop-types';
import SubNav from '../sub-nav';
import i18n from '../../utils/i18n';

const linksData = [
  { key: 'termsOfService', urlPath: 'terms_of_service' },
  { key: 'privacyPolicy', urlPath: 'privacy_policy' },
  { key: 'paymentPolicy', urlPath: 'payment_policy' },
  { key: 'responsibleDisclosurePolicy', urlPath: 'responsible_disclosure_policy' }
];

function LegalSubNav({ title, locale, renderedAsWebview }) {
  const webviewLayoutParams = renderedAsWebview ? '?layout=webview' : '';
  const legalLinks = linksData.map((linkData) => ({
    name: i18n.t(`legal.${linkData.key}.title`),
    url: `/${locale}/legal/${linkData.urlPath}${webviewLayoutParams}`
  }));
  return <SubNav title={title} links={legalLinks} />;
}

LegalSubNav.propTypes = {
  title: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  renderedAsWebview: PropTypes.bool
};

LegalSubNav.defaultProps = {
  renderedAsWebview: false
};

export default LegalSubNav;
